<template>
    <div :class="[student.checkin ? 'checkin' : '', 'container']" :key="student.id" v-for="student in students" @dblclick="$emit(`toggle-checkin`, student.id)">
        <div class=" item imagearea" >
            <img :src="student.photo" alt="Student Photo" class="image"/>
        </div>
        <div class="item data"><StudentData :student="student" /></div>
        <div><IndividualTool :student="student" /></div>
        <div><StudentStatus :studentStatus="student.status"/></div>
        <!-- <button @click="viewScheduleHistory">View Schedule History</button> -->
    </div>
</template>

<script>
import StudentData from "./IndividualData.vue"
import IndividualTool from "./IndividualTool.vue"
import StudentStatus from "./studentStatus.vue"
export default {
    name: "StudentsData",
    props: {
        students: {
            type: Array,
            required: true
        },

    },
    
    components: {
        StudentData,
        IndividualTool,
        StudentStatus
    },
    methods: {
        viewScheduleHistory() {
            // Implement the logic to view the schedule history here
        }
    },
    emits: ['toggle-checkin'] 
};
</script>

<style scoped>
.container {
  /* width: 80%;
  height: 180px;
  margin: auto;
  padding: 10px; */
  
  width: 100%;
  height: 70px;
  background: #f4f4f4;
  align-items: flex-start;
  margin: 2px 5px 3px 5px;
  padding: 0px 5px;
  cursor: pointer;
  gap: 4px;
  border-left: 6px solid #e74c3c;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
}

.imagearea {
  width: auto;
  height: 70px;
  background: whitesmoke;
  float: left;
  align-items: flex-start;
  overflow: hidden;
}

.image {
    max-width: 100%;
    max-height: 100%;
    border: 3px outset #c0c0c0;
    align-items: flex-start;
    overflow: hidden;
    border-radius: 50%;
}
.data {
  margin-left: 5px;
  height: 70px;
  width: auto;
  background: #f4f4f4;
  overflow: hidden;
}
.student {
  background: #f4f4f4;
  align-items: flex-start;
  margin: 5px;
  padding: 10px 5px;
  cursor: pointer;
  gap: 10px;
}

.checkin {
  border-left: 5px solid green;
}

.student.teacher {
    display: flex;
    align-items: left;
    margin: 0px;
    padding: 2px 4px;
    font-weight: lighter;
    font-size: 14pt;
    font-display: red;
}

.student h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 100%;
  
}
</style>