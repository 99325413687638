<template>
    <div class="list" :student="student">
        <div class="tools">
        <!-- <h1>Student Information</h1>
        <div v-for="student in students" :key="student.id">
            <h2>{{ student.name }}</h2>
            <p>Check-in Time: {{ student.checkinTime }}</p>
            <p>Contact List: {{ student.contactList }}</p>
            <p>Class Schedule: {{ student.classSchedule }}</p>
            <p>Whereabouts: {{ student.whereabouts }}</p>

        </div> -->
        <!-- <i @click="onClick(task.id)" class="fas fa-times"></i> -->
        <!-- <i @click="onBinoculars(student.id)" class="fa-duotone fa-binoculars"></i>
        <i @click="onBinoculars(student.id)" class="fa-duotone fa-binoculars"></i>
        <i @click="onLocation(student.id)" class="FontAwesomeIcon fa-location-pin"></i>
        <i @click="onStreetView(student.id)" class="fa-duotone fa-street-view"></i> -->
        <i v-if="IsSchooliconclicked" @click="onSchool(student.id)" class="fasred fas fa-school"></i>
        <i v-else-if="IsSchooliconclicked === false" @click="onSchool(student.id)" class="fas fa-school"></i>
        <i v-if="IsCalendariconclicked" @click="onCalendar(student.id)" class="fasred fas fa-calendar"></i>
        <i v-else-if="IsCalendariconclicked === false" @click="onCalendar(student.id)" class="fas fa-calendar"></i>
        <i v-if="IsLocationiconclicked" @click="onLocation(student.id)" class="fasred fas fa-location-pin"></i>
        <i v-else-if="IsLocationiconclicked === false" @click="onLocation(student.id)" class="fas fa-location-pin"></i>
        <!-- <i @click="onStreetView(student.id)" class="fa-duotone fa-swap-arrows"></i> -->
        </div>
        <div class="details">
            <div v-if="IsSchooliconclicked">
                <div v-if="student.checkin" class="status">
                    <div class="circle">

                    </div>
                <h7 class="h7">{{student.checkinTime}}</h7>
                </div>
            </div>
            
            
            <ul v-if="IsCalendariconclicked">
                <li v-for="pos in student.classSchedule" :key="pos.id" class="detailformat">
                    {{pos.time}} {{pos.subject}}
                    <i v-if="pos.checked" class="fascheck fas fa-check"></i>

                </li>
            </ul> 
            <ul v-if="IsLocationiconclicked">
                <li v-for="pos in student.whereabouts" :key="pos.id" class="detaillocationformat">
                    {{pos.time}} {{pos.location}}
                    <i @click="Call" class="fascheck fas fa-phone"></i>
                    <i @click="SendMessage" class="faspurple fas fa-message"></i>

                </li>
            </ul> 
            <!-- <li v-for="pos in student.whereabouts" :key="pos.id">
                    {{pos.time}} {{pos.location}}

                </li>  -->
        </div>
              
    </div>  
    

    
</template>

<script>
/* import the fontawesome core */
// import { library } from '@fortawesome/fontawesome-svg-core'
// /* import font awesome icon component */
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { fa-binoculars } from '@fortawesome/free-solid-svg-icons'

// /* import the fontawesome free solid icons */
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
// import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
/* import specific icons */
// import { all } from '@awesome.me/kit-KIT_CODE/icons'

/* add icons to the library */
// library.add(...all, FontAwesomeIcon)
// library.add(FontAwesomeIcon)


export default {
    name: 'IndividualTool',
    props: {
        student: {
            type: Object
        },
        // IsSchooliconclicked: {
        //     type: Boolean,
        //     default: true,
        // },
        // IsCalendariconclicked: {
        //     type: Boolean,
        //     default: false,
        // },  
        // IsLocationiconclicked: {
        //     type: Boolean,
        //     default: false,
        // },  
    },
    data() {
        return {
            IsSchooliconclicked: true,
            IsCalendariconclicked: false,
            IsLocationiconclicked: false,
        }
    },

    // components: {
    //     FontAwesomeIcon,
    // },

    methods: {
        onBinoculars(id) {
            console.log(`Binoculars clicked for student with id: ${id}`);
        },
        onLocation(id) {
            //console.log(`Location clicked for student with id: ${id}`);
            this.IsLocationiconclicked = !this.IsLocationiconclicked;
            if (this.IsLocationiconclicked === true)
            {
                this.IsSchooliconclicked = false;
                this.IsCalendariconclicked = false;
            }
            this.$emit('onLocation', id);
        },
        onStreetView(id) {
            console.log(`Street View clicked for student with id: ${id}`);
        },
        onSchool(id) {
            // console.log(`School clicked for student with id: ${id}`);
            this.IsSchooliconclicked = !this.IsSchooliconclicked;
            if (this.IsSchooliconclicked === true)
            {
                this.IsLocationiconclicked = false;
                this.IsCalendariconclicked = false;
            }
            this.$emit('onSchool', id);
        },
        onCalendar(id) {
            //console.log(`Calendar clicked for student with id: ${id}`);
            this.IsCalendariconclicked = !this.IsCalendariconclicked;
            if (this.IsCalendariconclicked === true)
            {
                this.IsSchooliconclicked = false;
                this.IsLocationiconclicked = false;
            }
            this.$emit('onCalendar', id);
        },
        Call() {
            // console.log(`Call clicked`);
            alert(`Call Mr. Moody to check whether the student is there`);
        },
        SendMessage() {
            // console.log(`Message clicked`);
            alert(`Send a message to Mr. Moody to check whether the student is there`);
        }
    },  
};
</script>

<style scoped>
.fas {
    color: #2196f3;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    margin: 2px 0px 4px 0px;
    opacity: 0.65;
}

.fasred {
    color: orange;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    margin: 2px 0px 4px 0px;
    opacity: 0.65;
}

.fascheck {
    color: green;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    margin: 2px 0px 0px 12px;
}

.faspurple {
    color:  #bb285c;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    margin: 2px 0px 0px 12px;
}

.fas-green {
    color: green;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    margin: 5px 0px 5px 0px;
}
.tools {
    grid-row: span 1;
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    margin: 0px 3px -1px 0px;
    padding: 0px;
}
.list {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 30px 250px 1px;
    /* justify-content:flex-start; */
    margin: 0px 3px 0px 0px;
    padding: 0px;
    /* border: white;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 30px; */
}

.details {
    grid-row: span 1;
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    /* font-size: 9pt;
    font-weight: lighter; */
    margin: 3px 0px 1px 5px;
    padding: 0px;
    border: white;
    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    height: 65px;
    align-items: left;
}

.detailformat {
    display: flex;
    margin: 0px 0px 0px 0px;
    padding: 0px;
    font: lighter;
    font-size: 8pt;
    font-weight: lighter;
    gap: 0px;
    align-items: left;
    /* height: 20px; */
    
}

.detaillocationformat {
    display: flex;
    margin: 0px 0px 0px 0px;
    padding: 0px;
    font: red;
    font-size: 8pt;
    font-weight: lighter;
    gap: 0px;
    align-items: left;
    /* background: lightblue; */
    /* height: 20px; */
    
}

.h7 {
    display: flex;
    margin: 4px 0px 0px 2px;
    padding: 0px;
    font: lighter;
    font-size: 11pt;
    font-weight: lighter;
    gap: 0px;
    align-items: left;
    /* height: 20px; */
    
}

.status {
    display: flex;
    margin: 0px 0px 0px 0px;
    
}
.circle {
    width: 8px;
    height: 8px;
    background: steelblue;
    border-radius: 50%;
    margin: 10px 0px 0px 4px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    /* font-size: 8pt; */
    /* font-weight: lighter; */
    /* gap: 0px; */
    /* align-items: left; */
    /* height: 20px; */
}   
</style>