<template>
    <div class="student-status">
        <div class="status-banner">
            <span class="status-text">{{ studentStatus }}</span>
        </div>
        <!-- Rest of your component content goes here -->
    </div>
    <!-- <div class="status-text">
        {{ studentStatus }}
    </div> -->
</template>

<script>
export default {
    name: 'StudentStatus',
    props: {
        studentStatus: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.student-status {
    /* Add your styles for the component container here */
    padding: 4px 0px;
    /* background-color: #CFD8DC; */
    margin: 0px 0px 0px -2px;
    background-color: #EEEEEE;
    /* opacity: 0.3; */
}

.status-banner {
    /* background-color: #CFD8DC; */
    padding: 10px 0px;
    margin: 0px;
    height: 64px;
    width: 100px;
    /* opacity: 0.3; */
    /* Add your styles for the status banner here */
}

.status-text {
    /* Add your styles for the status text here */
    z-index: higher;
    font-size: 12pt;
    /* font: #455A64; */
    font: bold;
    font-style: oblique;
    opacity: 1.0;
}
</style>