<template>
    <div :student="student" class="student">
        <div class="name">
            <h1 class="sh1">{{student.firstName}} {{student.lastName}}</h1>
        </div>
        
        <div>
            <div class="sh3">Grade: {{student.class}}</div>
            <div class="sh3"> {{student.teacher}}</div>
            <div class="sh3">Phone: {{student.phone}}</div>
        </div>
        
        <!-- <h4>Phone: {{student.phone}}</h4> -->
        
    </div>
</template>

<script>
export default {
    name: "StudentData",
    props: {
        student: {
            type: Object
        }
    },
    // methods: {
    //     toggleCheckin(id) {
    //         this.$emit('toggle-checkin', id)
    //     }
    // }
};
</script>

<style scoped>
.fas {
    color: red;
    cursor: pointer;
}
.container {
    background: #f4f4f4;
    margin: 0px;
    padding: 2px 5px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    font-size: 16pt;
    /* cursor: pointer; */
    /* border-left: 6px solid #e74c3c; */
}
.student {
    background: #f4f4f4;
    margin: 0px 0px 0px 4px;
    padding: 0px 0px;
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    /* cursor: pointer; */
    /* border-left: 6px solid #e74c3c; */
}

.student.checkin {
    border-left: 6px solid #2ecc71;
}

.name {
    font: #606060;
    margin: 0px
    /* font-size: 18pt; */
}

.student.teacher {
    display: flex;
    align-items: left;
    margin: 0px;
    padding: 2px 20px;
    font-weight: lighter;
    font-size: 14pt;
    font-display: red;
}

.sh1 {
    display: flex;
    align-items: top;
    font: #455A64;
    margin: -10px 2px -8px 0px;
    padding: 0px 0px;
    font-size: 16pt;
}

.sh3 {
    display: flex;
    font: #929292;
    justify-content: space-between;
    align-items: top;
    margin: 0px 0px -4px 15px;
    padding: 0px 0px 0px 0px;
    font-weight: normal;
    font-size: 10pt;
}

.student h4 {
    display: flex;
    font: #929292;
    align-items: top;
    margin: 0px;
    padding: 0px 4px;
    font-weight: lighter;
    font-size: 10pt;
}
</style>