<template>
  <div class="titlegrid">
    <h1 class="title">
    Grade 7: Mrs. Smith's Geography class
    </h1>
    <!-- <div class="messages"> 
      <img src="../images/message.png" v-on:click="checkmessages" class="image"/>
    </div> -->
  
  </div>
  
  <div class="Headergrid">
    <div class="itemcheckin" >
      
      <button @click="CheckInAllStudents" class="classbutton">Checkin All</button> 
      <img src="../images/message.png" v-on:click="checkmessages" class="image"/>
    <!-- <MDBBtn @click="CheckInAllStudents" color="info" rounded class="text-nowrap">CheckIn</MDBBtn> -->
    </div>
  </div>
  
  <div class="container">
    <StudentsData :students="studentsSchool" @toggle-checkin="onToggleCheckin"/>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import StudentsData from '@/components/StudentsData.vue'
// import { MDBBtn } from "mdb-vue-ui-kit";

export default {
  name: 'HomeView',

  // props: {
  //   classid: {
  //     type: Number,
  //     required: true,
  //   },
  // },

  data() {
    return {
      studentsSchool: [
      {
                id: {
                    type: Number,
                    required: true,
                },
                photo: {
                    type: String,
                    required: false,
                },
                firstName: {
                    type: String,
                    required: true,
                },
                lastName: {
                    type: String,
                    required: true,
                },
                gender: {
                    type: String,
                    required: false,
                },
                phone: {
                    type: String,
                    required: false,
                },
                class: {
                    type: String,
                    required: true,
                },
                teacher: {
                    type: String,
                    required: false,
                },
                status: {
                    type: String,
                    required: false,
                },
                email: {
                    type: String,
                    required: false,
                },
                address: {
                    type: String,
                    required: false,
                },
                contactList: {
                    type: Array,
                },
                classSchedule: {
                    type: Array,
                },
                whereabouts: {
                    type: Array,
                },
                checkin: {
                    type: Boolean,
                    required: false,
                },
                checkinTime: {
                    type: String,
                    required: false,
                },
            },
      ],
      classid : 108,
    }
  },
  components: {
    // HelloWorld,
    StudentsData,
    // MDBBtn
  },

  methods: {
    // Implement the logic to view the schedule history here
    viewScheduleHistory() {
    },

    CheckInAllStudents() {
      this.studentsSchool.forEach(student => {
        if (student.checkin === true)
        {
          student.status = "Geography Class";
          if (student.whereabouts.length > 0) 
          {
            if (student.whereabouts.filter(whereabout => (whereabout.id === this.classid)).length>0)
            {
              return;
            }
            else
            {
              const whereabout = {
                id: this.classid,
                time: '14:45 AM',
                location: 'Geography Class',
                // checked: true,
              };
              student.whereabouts = [whereabout, ...student.whereabouts];
            }
            // student.whereabouts.push({id: student.whereabouts.length + 1, time: '14:45 AM', location: 'Gym'});
            // const whereabout = {
            //   id: this.classid,
            //   time: '14:45 AM',
            //   location: 'Geography Class',
            //   // checked: true,
            // };
            // student.whereabouts = [whereabout, ...student.whereabouts];
          }
        }
          

      });
    },

    updateLocation(id) {
      console.log(`Location clicked for student with id: ${id}`);
    },
    onToggleCheckin(id) {
      // const student = this.studentsSchool.find(student => student.id === id);
      // student.checkin = !student.checkin;

      this.studentsSchool = this.studentsSchool.map((student) =>
      student.id === id ? { ...student, checkin: !student.checkin } : student
      );
    },
    checkmessages() {
      console.log('Check Messages');
    }, 
  },

  computed: {
    // Add computed properties here
    cssProps() {
      return {
        color: 'red',
        fontSize: '20px',
      };
    },
  },

  created() {
    this.studentsSchool = [
      {
        id: 1,
        photo: 'https://randomuser.me/api/portraits/men/10.jpg',
        firstName: 'John',
        lastName: 'Doe',
        gender: 'male',
        phone: '123-456-7890',
        class: '9th Grade',
        teacher: 'Mr. Smith',
        email: 'john.doe@gmail.com',
        address: '123 Main St.',
        contactList: [
          {
            id: 1,
            name: 'Jane Doe',
            phone: '123-456-7890',
            relationship: 'Mother',
          },
          {
            id: 2,
            name: 'Jim Doe',
            phone: '123-456-7890',
            relationship: 'Father',
          },
        ],
        classSchedule: [
          {
            id: 1,
            time: '9:00 AM',
            subject: 'Math',
            checked: false,
          },
          {
            id: 2,
            time: '10:00 AM',
            subject: 'English',
            checked: true,
          },
          {
            id: 3,
            time: '13:00 PM',
            subject: 'Swimming',
            checked: true,
          },
        ],
        whereabouts: [
          {
            id: 1,
            time: '07:13 AM',
            location: 'Gym Scan',
          },
          {
            id: 2,
            time: '08:30 AM',
            location: 'Math Class',
          },
        ],
        checkin: true,
        checkinTime: 'Checked-in 9:00AM Gym mobile #2',
      },
      {
        id: 2,
        photo: 'https://randomuser.me/portraits/women/3.jpg',
        firstName: 'Mary',
        lastName: 'Anne',
        gender: 'female',
        phone: '123-456-7890',
        class: '8th Grade',
        teacher: 'Ms. Johnson',
        email: 'jane.doe@yahoo.com',
        address: '123 Main St.',
        contactList: [
          {
            id: 1,
            name: 'John Anne',
            phone: '123-456-7890',
            relationship: 'Father',
          },
          {
            id: 2,
            name: 'Jim Anne',
            phone: '123-456-7890',
            relationship: 'Brother',
          },
        ],
        classSchedule: [
          {
            id: 2,
            time: '10:00 AM',
            subject: 'Chemistry',
            checked: true,
          },
          {
            id: 3,
            time: '13:00 PM',
            subject: 'Music',
            checked: false,
          },
        ],
        whereabouts: [
          {
            id: 1,
            time: '07:13 AM',
            location: 'Math Class',
          },
          {
            id: 2,
            time: '08:30 AM',
            location: 'English Class',
          },
        ],
        checkin: false,
        checkinTime: 'Checked-in 8:26 AM school entrance #2',
      },

      {
        id: 3,
        photo: 'https://randomuser.me/portraits/women/4.jpg',
        firstName: 'Jill',
        lastName: 'October',
        gender: 'female',
        phone: '703-456-7890',
        class: '7h Grade',
        teacher: 'Ms. Johnson',
        email: 'jill.october@gmail.com',
        address: '123 Main St.',
        checkin: true,
        checkinTime: 'Checked-in 9:05AM mobile #3',
        classSchedule: [
          {
            id: 1,
            time: '9:00 AM',
            subject: 'Math',
            checked: true,
          },
          {
            id: 2,
            time: '10:00 AM',
            subject: 'Math',
            checked: true,
          },
          {
            id: 3,
            time: '13:00 PM',
            subject: 'History',
            checked: false,
          },
        ],
        whereabouts: [
          {
            id: 1,
            time: '07:24 AM',
            location: 'Gym Scan',
          },
          {
            id: 2,
            time: '08:40 AM',
            location: 'Math Class',
          },
        ],
      },
      {
        id: 4,
        photo: 'https://randomuser.me/portraits/men/5.jpg',
        firstName: 'Jack',
        lastName: 'Anderson',
        gender: 'male',
        phone: '123-456-7890',
        class: '6th Grade',
        teacher: 'Mr. Smith',
        email: 'jack.anderson@yahoo.com',
        address: '123 Main St.',
        checkin: true,
        checkinTime: 'Checked-in 9:10AM auditorium #1',
        classSchedule: [
          
          {
            id: 2,
            time: '10:00 AM',
            subject: 'Writing',
            checked: false,
          },
          {
            id: 3,
            time: '13:00 PM',
            subject: 'European History',
            checked: false,
          },
        ],
        whereabouts: [
          {
            id: 1,
            time: '07:13 AM',
            location: 'Princial Office',
          },
          {
            id: 2,
            time: '08:30 AM',
            location: 'English Class',
          },
        ],

      },
      {
        id: 5,
        photo: 'https://randomuser.me/portraits/women/11.jpg',
        firstName: 'Helen',
        lastName: 'Smith',
        gender: 'female',
        phone: '123-456-7890',
        class: '5th Grade',
        teacher: 'Ms. Johnson',
        email: 'helen.smith@email.com',
        address: '123 Main St.',
        checkin: false,
        status: 'Called Sick',
        checkinTime: '',
        classSchedule: [
          {
            id: 1,
            time: '9:00 AM',
            subject: 'Math',
            checked: true,
          },
          {
            id: 2,
            time: '10:00 AM',
            subject: 'English',
            checked: false,
          },
          {
            id: 3,
            time: '13:00 PM',
            subject: 'Swimming',
            checked: true,
          },
        ],
        whereabouts: [
        ],
      },
      {
        id: 6,
        photo: 'https://randomuser.me/portraits/women/21.jpg',
        firstName: 'Cassy',
        lastName: 'Wu',
        gender: 'female',
        phone: '123-456-7890',
        class: '4th Grade',
        teacher: 'Mr. Smith',
        email: 'cassy.chen@yahoo.com',
        address: '123 Main St.',
        checkin: false,
        checkinTime: 'Checked-in 9:20AM Gym m#4',
        classSchedule: [
          {
            id: 1,
            time: '9:30 AM',
            subject: 'Math',
            checked: false,
          },
          {
            id: 3,
            time: '13:00 PM',
            subject: 'Swimming',
            checked: true,
          },
        ],
        whereabouts: [
          {
            id: 1,
            time: '07:13 AM',
            location: 'Gym Scan',
          },
          {
            id: 2,
            time: '08:30 AM',
            location: 'Spanish Class',
          },
          {
            id: 4,
            time: '14:45 AM',
            location: 'Gym',
          },
        ],
      },

    ]
  }

}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}



.classbutton {
  white-space: nowrap;
  background: #006e8c;
  color: #fff;
  border: none;
  padding: 8px 20px;
  margin: -5px 5px 5px 5px;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  font-size: 12pt;
  height: 40px;
  /* flex-direction: row;
  justify-content:flex-end; */
}

.container {
  width: 100%;
  margin: 5px auto;
  overflow: hidden;
  min-height: 300px;
  border: 1px solid steelblue;
  padding: 3px 3px;
  border-radius: 5px;
  flex: content;
  gap: 0px;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
}

.titlegrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  margin: 5px auto;
  overflow: hidden;
  /* border: 0px solid steelblue; */
  padding: 3px 3px;
  gap: 5px;
  
}

.title {
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: left;
  text-align: left;
  margin: -5px 10px -1px 25px;
}

.Headergrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  margin: 5px auto;
  overflow: hidden;
  /* border: 0px solid steelblue; */
  padding: 3px 3px;
  border-radius: 5px;
  gap: 5px;
  
}

.messages {
    grid-column-start: 2;
    grid-column-end: 3;
    display: flex;
    justify-content: right;
    /* margin: -50px -150px 0px 0px; */
}

.image {
    width: 60px;
    height: 42px;
    margin: -5px 5px 5px 15px;
    padding: 0px;
    border: 0px;
    /* border-radius: 50%; */
    cursor: pointer;

}


.itemcheckin {
    grid-column-start: 3;
    grid-column-end: 3;
    justify-content: right;
}

.container.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn {
  display: inline-block;
  background: #006e8c;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  width: 10%;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}
</style>

